import React, { useEffect, useRef } from "react"
import Page, { PageHeight } from "../components/Page"
import Layout from "../components/Layout"
import SiteMeta from "../components/SiteMeta"
import commonStyles from "./pages.module.scss"
import styles from "./contact.module.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "../components/BackgroundImage"

import { object, string } from "yup"
import { useFormik } from "formik"
import { stringify } from "qs"
import axios from "axios"
import { toast } from "react-toastify"
import classNames from "classnames"
import { OutboundLink } from "gatsby-plugin-google-analytics"

type Values = {
  email: string
  message: string
  "bot-field": string
  "form-name": "contact"
}

const validationSchema = object().shape({
  email: string()
    .email("Please enter valid email")
    .required("Please enter your email"),
  message: string().required("Please enter a message"),
})

const Contact: React.FC = () => {
  const ref = useRef<HTMLFormElement | null>(null)

  useEffect(() => {
    ref.current?.setAttribute("novalidate", "novalidate")
  }, [ref])

  const data = useStaticQuery<GatsbyTypes.ContactQuery>(graphql`
    query Contact {
      image: file(
        relativePath: { eq: "tomasz-filipek-b6tOS6FPjFY-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ogImage: file(relativePath: { eq: "og-image-contact.png" }) {
        childImageSharp {
          fixed(quality: 60, width: 1200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const {
    isSubmitting,
    handleSubmit,
    errors,
    submitCount,
    isValid,
    touched,
    getFieldProps,
  } = useFormik<Values>({
    initialValues: {
      email: "",
      message: "",
      "bot-field": "",
      "form-name": "contact",
    },
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        await axios({
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: stringify(values),
          url: "/",
        })
        resetForm()
        toast("Thanks! I will get back to you as soon as possible!", {
          type: "success",
        })
      } catch (e) {
        toast(e.message, { type: "error" })
      }

      setSubmitting(false)
    },
    validationSchema,
  })

  const hasFormErrors = submitCount > 0 && !isValid
  const shouldShake = submitCount > 0 && !isValid && !isSubmitting

  return (
    <Layout>
      <SiteMeta
        title="Contact"
        description="Got a project in mind we could work on together? Let me know!"
        cardImage={data?.ogImage?.childImageSharp?.fixed}
      />
      <Page className={commonStyles.headerWrapper}>
        <div
          className={classNames(
            commonStyles.container,
            commonStyles.headerContainer
          )}
        >
          <h1>Let’s get&nbsp;in touch</h1>
          <p className={commonStyles.lead}>
            Got a project in mind we could work on together? Let me
            know&mdash;I’ll get back to you as soon as possible!
          </p>
          <p className={commonStyles.lead}>
            You can find me on{" "}
            <OutboundLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/in/aleksimunter/"
            >
              LinkedIn
            </OutboundLink>
            ,{" "}
            <OutboundLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://instagram.com/aleksimunter"
            >
              Instagram
            </OutboundLink>
            ,{" "}
            <OutboundLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://twitter.com/AleksiMunter"
            >
              Twitter
            </OutboundLink>{" "}
            and{" "}
            <OutboundLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/aleksi.munter"
            >
              Facebook
            </OutboundLink>
            , or you can send me a message right here.
          </p>

          <form
            ref={ref}
            onSubmit={handleSubmit}
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            className={styles.form}
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <label htmlFor="email">
              Email
              {touched.email && errors.email && (
                <span
                  className={classNames(
                    styles.error,
                    shouldShake && styles.shake
                  )}
                >
                  {errors.email}
                </span>
              )}
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              {...getFieldProps("email")}
            />

            <label htmlFor="message">
              Message
              {touched.message && errors.message && (
                <span
                  className={classNames(
                    styles.error,
                    shouldShake && styles.shake
                  )}
                >
                  {errors.message}
                </span>
              )}
            </label>
            <textarea
              id="message"
              name="message"
              rows={10}
              required
              {...getFieldProps("message")}
            />

            <div className={styles.controls}>
              {hasFormErrors && (
                <div
                  className={classNames(
                    styles.error,
                    shouldShake && styles.shake
                  )}
                >
                  Please check the form for errors
                </div>
              )}
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Sending..." : "Send"}
              </button>
            </div>
          </form>
        </div>
        <BackgroundImage
          className={classNames(commonStyles.headerImage, styles.bgImage)}
          displayId="contact-header"
          fluid={data.image?.childImageSharp?.fluid}
        />
      </Page>
    </Layout>
  )
}

export default Contact
